import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios, { AxiosResponse } from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy, faUsers, faImages, faCameraRetro } from '@fortawesome/free-solid-svg-icons';

import { useClassnames } from 'hook/use-classnames';
import Button from 'component/button';
import UI from 'component/ui';
import Post from 'route/home/post';
import Loader from 'component/loader';

import { Data as CommonStatisticsData } from 'component/api/types/api/statistics/common/get/code-200';
import style from './index.pcss';
import api from 'src/api';
import { Page, PaginationResponse } from 'src/api/base';
import { Event, EventsSearchFilter } from 'src/api/events/types';

const EVENTS_LIMIT = 15;

const prepareCount = (count: number): string => {
    if (count > 1000000) {
        return `${Math.round(count / 1000000)}M`;
    }

    if (count > 1000) {
        return `${Math.round(count / 1000)}K`;
    }

    return `${count}`;
};

export default () => {
    const cn = useClassnames(style);
    const { t } = useTranslation();

    const [pending, setPending] = useState<boolean>(false);
    const [pendingBefore, setPendingBefore] = useState<boolean>(false);
    const [list, setList] = useState<Array<Event>>([]);
    const [total, setTotal] = useState<number>(0);

    const [statisticsData, setStatisticsData] = useState<CommonStatisticsData>();
    const [pendingStatistics, setPendingStatistics] = useState<boolean>(false);

    const _request = () => {
        if(!pending && !pendingBefore) {
            setPending(true);

            const page: Page = {
                pageNumber: 1,
                pageSize: EVENTS_LIMIT
            };

            const filter: EventsSearchFilter = {
                is_empty: false,
                is_parent_only: true
            };

            api.events.getEventsList(page, filter)
                .then((resp: AxiosResponse<PaginationResponse<Event>>) => {
                    const newList = resp.data.results;

                    setTotal(resp.data.count);
                    setPending(false);
                    setPendingBefore(false);
                    setList(newList);
                })
                .catch((err: {message: string}) => {
                    if(!axios.isCancel(err)) {
                        setPending(false);
                        setPendingBefore(false);
                    }
                });
        }
    };

    const _requestCommonStatistics = () => {
        setPendingStatistics(true);

        api.statistic.getCommonStatistics()
            .then((resp) => {
                setStatisticsData(resp.data);
                setPendingStatistics(false);
            })
            .catch((err) => {
                if(!axios.isCancel(err)) {
                    setPendingStatistics(false);
                }
            });
    };

    useEffect(() => {
        _request();
        _requestCommonStatistics();
    }, []);

    const elButtonBeforePosts = () => {
        if(!pending && list.length) {
            if(list.length === total) {
                return;
            }

            return <Button isSmall={true} to={'/search'}>Больше событий</Button>;
        }
    };

    const elTopContent = useMemo(() => {
        if (pendingStatistics) {
            return <Loader />;
        }

        if (statisticsData) {
            const items = [{
                icon: faImages,
                count: statisticsData.photo_count,
                short: prepareCount(statisticsData.photo_count),
                text: 'Загружено фотографий'
                // text: t('route.home.center.photos')
            }, {
                icon: faTrophy,
                count: statisticsData.event_count,
                short: prepareCount(statisticsData.event_count),
                text: 'Размещено событий'
                // text: t('route.home.center.detect')
            }, {
                icon: faCameraRetro,
                count: statisticsData?.photographer_count,
                short: prepareCount(statisticsData.photographer_count),
                text: 'Участвует фотографов'
                // text: t('route.home.center.find')
            }, {
                icon: faUsers,
                count: statisticsData?.person_count,
                short: prepareCount(statisticsData.person_count),
                text: 'Распознано персон'
                // text: t('route.home.center.notice')
            }];

            return (
                <section className={cn('home__top')}>
                    <UI.Main className={cn('home__top-content')}>
                        {items.map((item, index) => (
                            <div className={cn('home__top-item')} key={index}>
                                <div className={cn('home__top-item-image')}>
                                    <FontAwesomeIcon icon={item.icon} />
                                </div>
                                <div className={cn('home__top-item-text')}>{item.text}</div>
                                <div data-tip={item.count} className={cn('home__top-item-count')}>{item.short}</div>
                            </div>
                        ))}
                    </UI.Main>
                </section>
            );
        }
    }, [pendingStatistics, statisticsData]);

    const elBottomContent = useMemo(() => {
        if(pending && !list.length) {
            return <Loader />;
        }

        if(list.length) {
            return (
                <div className={cn('home__items')}>
                    {list.map((item, index) => (
                        <Post
                            sell_suit_count={item.sell_suit_count}
                            key={index}
                            name={item.name}
                            id={item.id}
                            photo_count={item.photo_count}
                            photos={item.photos}
                            location_name={item.location_name}
                            date={item.date}
                            className={cn('home__post')}
                        />
                    ))}
                </div>
            );
        }

        return (
            <UI.Box padding={true} className={cn('home__posts-empty')}>
                {t('route.home.post.empty')}
            </UI.Box>
        );
    }, [JSON.stringify(list), pending]);

    return (
        <main className={cn('home')}>
            {elTopContent}
            <section className={cn('home__bottom')}>
                <UI.Main className={cn('home__bottom-content')}>
                    {elBottomContent}
                    {elButtonBeforePosts()}
                </UI.Main>
            </section>
        </main>
    );
};
