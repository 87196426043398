import React, { useState, MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import style from './index.pcss';

// utils
import { useClassnames } from 'hook/use-classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// components
import UI from 'component/ui';
import Button from 'component/button';
import { IProps } from './types';
import api from 'src/api';
import history from 'component/core/history';
import { IStore } from 'store/reducers/types/reducers';
import { key as keyUser } from 'store/reducers/user/reducer';
import ArrowDown from 'component/icon/arrow-dir-down';
import { useAlert } from 'component/alert/provider';

const NewPost = (props: IProps) => {
    const cn = useClassnames(style);
    const { t } = useTranslation();
    const isAuth = useSelector<IStore, boolean>((storeApp) => !!storeApp[keyUser].id);
    const { show } = useAlert();

    const [isCollapsed, setIsCollapsed] = useState(false);

    const getAge = () => {
        const today = new Date();
        const birthDate = new Date(props.person.birthday);
        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();

        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }

        return age;
    };

    const danceClass = (value: number) => {
        switch (value) {
            case 1:
                return 'H';
            case 2:
                return 'E';
            case 3:
                return 'D';
            case 4:
                return 'C';
            case 5:
                return 'B';
            case 6:
                return 'A';
            case 7:
                return 'S';
            case 8:
                return 'M';
        }
    };

    const onClickArrow = (e: MouseEvent) => {
        e.preventDefault();

        if (!isAuth) {
            history.push('/login?from=/find-partner');

            return;
        }

        setIsCollapsed((prev) => !prev);
    };

    const onClickRespond = (id: number) => (event: MouseEvent) => {
        event.stopPropagation();
        event.preventDefault();

        if (!isAuth) {
            history.push('/login?from=/find-partner');

            return;
        }

        api.partner.respond(id).then(() => {
            show('На вашу почту отправлены контактные данные для связи.');
        });
    };

    return (
        <UI.Box padding={false} className={cn('post')}>
            <div className={cn('post__partner')}>
                <div className={cn('post__partner__card')}>
                    <div className={cn('post__partner__card-avatar')}>
                        <Link to={`persons/${props.person?.person.id}`}>
                            <div className={cn('post__partner__card__photo')}>
                                <img alt={props.person.person.first_name} src={props.person.person.photo} className={cn('post__partner-photo')} />
                            </div>
                        </Link>
                    </div>
                    <div className={cn('post__partner__card-name')}>
                        <Link to={`persons/${props.person?.person.id}`}>
                            {(props.person.person.first_name && props.person.person.last_name) ? props.person.person.full_name : props.person.person.id}
                        </Link>
                    </div>
                    <div className={cn('post__partner__card-info')}>
                        <div className={cn('post__partner__card__item')}>Город: {props.person.location?.name || 'не указан'}</div>
                        <div className={cn('post__partner__card__item')}>Рост: {props.person?.height ? `${props.person?.height} см` : 'не указан'}</div>
                        <div className={cn('post__partner__card__item')}>
                            Пол: {props.person?.gender === 'MALE' ? 'муж.' : 'жен.'}
                        </div>
                        <div className={cn('post__partner__card__item')}>
                            Возраст: {props.person?.birthday && getAge()}
                        </div>
                        <div className={cn('post__partner__card__item')}>
                            Класс стандарт: {props.person?.standard_grade ? danceClass(props.person?.standard_grade) : 'н/у'}
                        </div>
                        <div className={cn('post__partner__card__item')}>
                            Класс латина: {props.person?.standard_latin ? danceClass(props.person?.standard_latin) : 'н/у'}
                        </div>
                    </div>
                    <div
                        className={cn('post__partner__card-btn', 'post__partner__card-btn_deactivated', {
                            'post__partner__card-btn_activated': isCollapsed
                        })}
                    >
                        <ArrowDown onClick={onClickArrow} />
                    </div>
                </div>
                {
                    isCollapsed && (
                        <div className={cn('post__partner__info')}>
                            <div className={cn('post__partner__info__wrapper')}>
                                <div className={cn('post__partner__info__item')}>Телефон для связи {props.person.phone_number}</div>
                                <div className={cn('post__partner__info__item')}>
                                    {props.person.coach_phone_number ? `Телефон тренера ${props.person.coach_phone_number}` : ''}
                                </div>
                                {
                                    props.person.dance_site_link && (
                                        <div className={cn('post__partner__info__item-link')}>
                                            <a href={props.person.dance_site_link}>Ссылка ФТСАРР</a>
                                        </div>
                                    )
                                }
                            </div>
                            {
                                props.person.comment && (
                                    <div className={cn('post__partner__info__comment')}>
                                        {props.person.comment}
                                    </div>
                                )
                            }
                            <div className={cn('post__partner__info__button')}>
                                <Button onClick={onClickRespond(props.person.id)}>Отправить контакты на почту</Button>
                            </div>
                        </div>
                    )
                }
            </div>
        </UI.Box>
    );
};

export default NewPost;
