import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useClassnames } from 'hook/use-classnames';

import styles from '../index.pcss';
import { Event, EventsSearchFilter } from 'src/api/events/types';
import Loader from 'component/loader';
import Button from 'component/button';
import Post from 'route/home/post';
import UI from 'component/ui';
import { Page } from 'src/api/base';
import api from 'src/api';

interface IProps {
    parent?: string;
    photographer_filter: string | null;
}

const EVENTS_LIMIT = 2;

const EventSubEvents = ({ parent, photographer_filter }: IProps) => {
    const cn = useClassnames(styles);
    const [isEventListPending, setIsEventListPending] = useState<boolean>(
        false
    );
    const [isEventListLoadMore, setIsEventListLoadMore] = useState<boolean>(
        false
    );
    const [eventListPage, setEventListPage] = useState<number>(1);
    const [isEventListNext, setIsEventListNext] = useState<boolean>(true);
    const [eventList, setEventList] = useState<Array<Event>>([]);
    const [eventListTotal, setEventListTotal] = useState<number>(0);

    useEffect(() => {
        setEventListPage(1);
        setIsEventListNext(true);
        setIsEventListPending(true);
    }, [parent]);

    useEffect(() => {
        setIsEventListNext(true);
        setEventListPage(1);
        setIsEventListPending(true);
    }, [photographer_filter]);

    useEffect(() => {
        if (isEventListPending || isEventListLoadMore) {
            const page: Page = {
                pageNumber: eventListPage,
                pageSize: EVENTS_LIMIT
            };

            const data: EventsSearchFilter = {
                is_empty: false,
                ...(photographer_filter && {photographer_id: Number(photographer_filter)})
            };

            api.events
                .getEventEventsList(Number(parent), page, data)
                .then((resp) => {
                    setEventListTotal(resp.data.count);
                    setEventList(
                        isEventListLoadMore
                            ? [...eventList, ...resp.data.results]
                            : resp.data.results
                    );
                    setIsEventListPending(false);
                    setIsEventListLoadMore(false);
                    setIsEventListNext(!!resp.data.next);
                })
                .catch(() => {
                    setIsEventListPending(false);
                    setIsEventListLoadMore(false);
                    setIsEventListNext(false);
                });
        }
    }, [isEventListPending, isEventListLoadMore]);

    const onClickMoreEvents = (e: React.MouseEvent): void => {
        e.preventDefault();

        if (isEventListNext) {
            setEventListPage((prev) => prev + 1);
            setIsEventListLoadMore(true);
        }
    };

    const elButtonBeforeSubEvents = () => {

        if (isEventListLoadMore) {
            return <Loader />;
        }

        if (
            !isEventListPending &&
            !isEventListLoadMore &&
            eventList?.length &&
            isEventListNext
        ) {
            if (!(eventList?.length - 1 === eventListTotal)) {
                return (
                    <Button
                        disabled={isEventListPending}
                        isLoading={isEventListPending || isEventListLoadMore}
                        isSecondary={true}
                        onClick={onClickMoreEvents}
                    >
                        Загрузить ещё
                    </Button>
                );
            }
        }
    };

    const elSubEventsContent = useMemo(() => {
        if (isEventListPending && !eventList.length) {
            return <Loader />;
        }

        if (eventList.length) {
            return (
                <Fragment>
                    <UI.PageHeader text="Вложенные события" />
                    <div className={cn('event-sub__items')}>
                        {eventList.map((item, index) => (
                            <Post
                                sell_suit_count={item.sell_suit_count}
                                key={index}
                                name={item.name}
                                id={item.id}
                                photo_count={item.photo_count}
                                photos={item.photos}
                                location_name={item.location_name}
                                date={item.date}
                                className={cn('event-sub__post')}
                                query={`?parent=${parent}`}
                                isBlankLink={true}
                            />
                        ))}
                    </div>
                </Fragment>
            );
        }
    }, [JSON.stringify(eventList), isEventListPending]);

    return (
        <div className={cn('event-sub')}>
            {elSubEventsContent}
            {elButtonBeforeSubEvents()}
        </div>
    );
};

export default EventSubEvents;
