import React, { Fragment, useEffect, useMemo, useState } from 'react';
import EyeIcon from 'component/icon/eye';
import Button from 'component/button';
import { IValue } from 'component/form/input-clubs/types';
import Form, { useRegistry } from 'component/form';
import Input from 'component/form/input';
import InputLocation from 'component/form/input-location';
import { useTranslation } from 'react-i18next';
import { Person, UpdateRequestPersonData } from 'src/api/persons/types';
import { IProps } from 'component/person-edit/edit-form/types';
import { useClassnames } from 'hook/use-classnames';
import style from 'component/person-edit/index.pcss';
import api from 'src/api';
import { useAlert } from 'component/alert/provider';
import Modal from 'component/modal';
import UI from 'component/ui';
import Loader from 'component/loader';

export default (props: IProps) => {
    const cn = useClassnames(style);
    const { t } = useTranslation();
    const { form, field } = useRegistry();
    const { show, hide } = useAlert();

    const [person, setPerson] = useState<Person>(props.person);
    const [image, setImage] = useState<Blob | null>(null);
    const [imageSrc, setImageSrc] = useState<string | null>(props.person.photo);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isNewPhotoLoading, setIsNewPhotoLoading] = useState<boolean>(false);
    const [uploadedFile, setUploadedFile] = useState<File | undefined>();
    const [isShowConfirmationModal, setIsShowConfirmationModal] = useState<boolean>(false);
    const [tmpPhotoFile, setTmpPhotoFile] = useState<string | null>(null);

    useEffect(() => {
        if (person) {
            setIsLoading(false);
        }

    }, [person]);

    const onSubmit = (): void => {
        let isValid = true;
        const fields = form.getFields();

        if (fields) {
            const fieldFirstName = fields.get('first_name');

            if (fieldFirstName && !fieldFirstName.value) {
                fieldFirstName.setError('Поле не может быть пустым!');
                isValid = false;
            }
            const fieldLastName = fields.get('last_name');

            if (fieldLastName && !fieldLastName.value) {
                fieldLastName.setError('Поле не может быть пустым!');
                isValid = false;
            }

            const fieldPhoto = fields.get('photo');

            if (fieldPhoto && !fieldPhoto.value) {
                fieldPhoto.setError('Фото должно быть загружено!');
                isValid = false;
            }

            if (isValid) {
                const payload = form.getPayload();

                const data: UpdateRequestPersonData = {
                    first_name: payload.first_name,
                    last_name: payload.last_name,
                    ...(payload.trainer_name && { trainer_name: payload.trainer_name }),
                    ...(payload.location.value && { location: payload.location.value })
                };

                api.persons.updateRequestPerson(person.id, data)
                    .then(
                        () => {
                            show('Ваши изменения отправлены на модерацию, после принятия администратором данные будут изменены.');
                            props.onSubmit();
                        },
                        (error) => {
                            if (error.response.data[0]) {
                                show(error.response.data[0], 'warning');
                            } else {
                                show('Не удалось отправить заявку на модерацию.', 'warning');
                            }
                        }
                );
            }
        }
    };

    const elTitle = () => {
        return (
            <a
                className={cn('person-edit__header')}
                target="_blank"
                href={`/persons/${person?.id}`}
                title="Перейти на страницу персоны"
            >
                {t('route.persons.content.modal.header')}
                <EyeIcon
                    width={24}
                    height={24}
                    className={cn('person-edit__header-eye')}
                />
            </a>
        );
    };

    const createPhotoUpdateRequest = () => {
        if (tmpPhotoFile) {
            setIsNewPhotoLoading(true);
            const data = {
                file: tmpPhotoFile
            };

            api.persons.updatePersonPhoto(props.person.id, data)
                .then(() => {
                    setIsShowConfirmationModal(false);
                    setIsNewPhotoLoading(false);
                    show('Ваши изменения отправлены на модерацию, после принятия администратором фотография будет изменена.', 'success');
                })
                .catch((error) => {
                    if (error.response.data[0]) {
                        show(error.response.data[0], 'warning');
                    } else {
                        show('Не удалось отправить заявку на изменение фотографии.', 'warning');
                    }
                    setIsNewPhotoLoading(false);
                })
                .finally(() => {
                    setTimeout(() => {
                        hide();
                    }, 5000);
                });
        }
    };

    const elUploadContent = useMemo(() => {
        const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            const file = event.target.files?.[0];

            setTmpPhotoFile(null);
            setUploadedFile(file);
            setIsShowConfirmationModal(true);

            if (file) {
                const formData = new FormData();

                formData.append('file', file);
                api.files.createTempFile(formData)
                    .then((resp) => {
                        setTmpPhotoFile(resp.data.id);
                    })
                    .catch((error) => {
                        if (error.response.data[0]) {
                            show(error.response.data[0], 'warning');
                        } else {
                            show('При загрузке фотографии произошла ошибка.', 'warning');
                        }
                        setTimeout(() => {
                            hide();
                        }, 5000);
                    });
            }
        };

        return (
            <Fragment>
                {imageSrc && (
                    <div className={cn('person-edit__image-box')}>
                        <img alt={imageSrc} className={cn('person-edit__photo-image')} src={imageSrc} />
                    </div>
                )}
                <Button
                    className={cn('person-edit__file-button')}
                    disabled={isNewPhotoLoading}
                    isLoading={isNewPhotoLoading}
                    onClick={() => document.getElementById('file-input')?.click()}
                >
                    Изменить фото
                </Button>
                <input
                    type="file"
                    id="file-input"
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                />
            </Fragment>
        );
    }, [JSON.stringify(image), imageSrc, isNewPhotoLoading]);

    const elButtons = () => {
        return (
            <div className={cn('person-edit__buttons')}>
                <Button
                    type="submit"
                    isLoading={isLoading}
                    className={cn('person-edit__button')}
                    isSmall={true}
                >
                    Сохранить
                </Button>
            </div>
        );
    };

    const elEditForm = () => {
        const inputLocationDefault: IValue | undefined = person.location
            ? {
                label  : person.location.name,
                value  : String(person.location.id),
                payload: person.location
            }
            : undefined;

        return (
            <Form
                registry={form}
                onSubmit={onSubmit}
                className={{ 'form__element': cn('person-edit__form') }}
            >
                <div className={cn('person-edit__fields-content')}>
                    <div className={cn('person-edit__file')}>
                        {elUploadContent}
                    </div>
                    <div className={cn('person-edit__fields')}>
                        <Input
                            registry={field}
                            name="first_name"
                            children={t('route.persons.content.modal.first_name')}
                            direction="column"
                            defaultValue={person.first_name}
                        />
                        <Input
                            registry={field}
                            name="last_name"
                            children={t('route.persons.content.modal.last_name')}
                            direction="column"
                            defaultValue={person.last_name}
                        />
                        <InputLocation
                            registry={field}
                            name="location"
                            children={t('route.persons.content.modal.city')}
                            direction="column"
                            placeholder="Выберите город"
                            defaultValue={inputLocationDefault}
                        />
                        <Input
                            registry={field}
                            name="trainer_name"
                            children={t('route.persons.content.modal.trainer')}
                            direction="column"
                            defaultValue={person.trainer_name}
                        />
                    </div>
                </div>
                {elButtons()}
            </Form>
        );
    };

    const elConfirmationModal = () => {
        if (isShowConfirmationModal) {
            const uploadedFileSrc = window.URL.createObjectURL(uploadedFile as Blob);

            const onClickCancel = () => {
                setTmpPhotoFile(null);
                setIsShowConfirmationModal(false);
                setUploadedFile(undefined);
                (document.getElementById('file-input') as HTMLInputElement).value = '';
            };

            return (
                <Modal onClickClose={() => setIsShowConfirmationModal(false)}>
                    <UI.BoxHeader className={cn('confirmation-modal__header')}>
                        Подтверждение действия
                    </UI.BoxHeader>
                    <UI.Main className={cn('confirmation-modal__content')}>
                        <p className={cn('confirmation-modal__content-text')}>
                            Запрос на изменение фотографии персоны
                        </p>
                        <span className={cn('confirmation-modal__content-subtext')}>
                            Следующая фотография будет отправлена на модерацию:
                        </span>
                        <div className={cn('confirmation-modal__content-image')}>
                            {tmpPhotoFile ?
                                <img alt={uploadedFileSrc} src={uploadedFileSrc} />
                            : <Loader />}
                        </div>
                    </UI.Main>
                    <UI.Main className={cn('confirmation-modal__footer')}>
                        <Button isSecondary={true} onClick={onClickCancel}>
                            Отменить
                        </Button>
                        <Button disabled={!tmpPhotoFile || isNewPhotoLoading} onClick={createPhotoUpdateRequest}>
                            Подтвердить
                        </Button>
                    </UI.Main>
                </Modal>
            );
        }
    };

    return (
        <Fragment>
            {elTitle()}
            {elEditForm()}
            {elConfirmationModal()}
        </Fragment>
    );
};
