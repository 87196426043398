import Modal from 'component/modal';
import React, { useEffect, useMemo, useState } from 'react';
import { AdItem, PartialUpdateAdData } from 'src/api/ads/types';
import UI from 'component/ui';
import style from './index.pcss';
import { useClassnames } from 'hook/use-classnames';
import Button from 'component/button';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment, faEyeDropper, faPhone, faTshirt, faUser } from '@fortawesome/free-solid-svg-icons';
import { getColor } from '../utils';
import EditAdModalCard from './edit';
import { useRegistry } from 'component/form';
import api from 'src/api';
import { useAlert } from 'component/alert/provider';

interface IProps {
    item: AdItem;
    type?: string;
    isOnModeration?: boolean;
    onClickClose(): void;
    updateList(): void;
    isOwner?: boolean;
}

const DetailedModalCard = (props: IProps) => {
    const cn = useClassnames(style);
    const { show, hide } = useAlert();
    const { form, field } = useRegistry();

    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const [isWarningModalShow, setIsWarningModalShow] = useState<boolean>(false);
    const [personGender, setPersonGender] = useState<string>('');

    useEffect(() => {
        switch (props.item.gender) {
            case 'man':
                setPersonGender('Муж.');
                break;
            case 'woman':
                setPersonGender('Жен.');
                break;
            default:
                break;
        }
    }, []);

    const onClickDelete = () => {
        setIsWarningModalShow(true);
    };

    const onClickCancelDelete = () => {
        setIsWarningModalShow(false);
    };

    const onClickSubmitDelete = () => {
        setIsWarningModalShow(false);
        if (props.item.status !== 'Published') {
            api.ads.closeCreate(props.item.id)
                .then(() => {
                    props.onClickClose();
                    props.updateList();
                    show('Ваше объявление успешно удалено');
                })
                .catch(() => {
                    show('Произошла ошибка при удалении объявления. Попробуйте позже');
                });
            } else {
                api.ads.destroyAd(props.item.id)
                .then(() => {
                    props.onClickClose();
                    props.updateList();
                    show('Ваше объявление успешно удалено');
                })
                .catch(() => {
                    show('Произошла ошибка при удалении объявления. Попробуйте позже');
                });
        }
    };

    const onClickCancel = () => {
        setIsEditMode(false);
    };

    const onClickEdit = () => {
        setIsEditMode(true);
    };

    const onClickSubmit = () => {
        const formData = form.getPayload();

        if (formData.color[0] === undefined) {
            return;
        }

        if (form.checkValidity()) {
            const data: PartialUpdateAdData = {
                color: formData.color[0],
                min_height: formData.height.value_from,
                max_height: formData.height.value_to,
                suit_size: formData.suit_size.value,
                gender: formData.gender.value,
                additional_phone_number: formData.additional_phone_number,
                comment: formData.comment || ''
            };

            api.ads.partialUpdateAd(props.item.id, data)
                .then(() => {
                    show('Изменения успешно сохранены');

                    setTimeout(hide, 2000);
                    setIsEditMode(false);
                    props.onClickClose();
                    props.updateList();
                });
        }
    };

    const onClickRespond = () => {
        api.ads.createRespond(props.item.id)
            .then(() => {
                    show('На вашу почту отправлены контактные данные для связи.');
                    setTimeout(hide, 5000);
                });
    };

    const elWarningModal = useMemo(() => {
        if (isWarningModalShow) {
            return (
                <Modal
                    onClickClose={() => setIsWarningModalShow(false)}
                    className={cn('warning-modal')}
                    classNameContent={cn('warning-modal-content')}
                >
                    <UI.BoxHeader>
                        Предупреждение
                    </UI.BoxHeader>
                    <UI.Content className={cn('warning-modal__content')}>
                        <div className={cn('warning-modal__content__info')}>
                            Вы уверены, что хотите удалить данное объявление?
                        </div>
                        <div className={cn('warning-modal__content__controls')}>
                            <Button onClick={onClickCancelDelete}>
                                Отмена
                            </Button>
                            <Button onClick={onClickSubmitDelete}>
                                Удалить
                            </Button>
                        </div>
                    </UI.Content>
                </Modal>
            );
        }
    }, [isWarningModalShow]);

    return (
        <Modal onClickClose={props.onClickClose} className={cn('ad-modal')}>
            {elWarningModal}
            <UI.BoxHeader className={cn('ad-modal__header')}>
                {props.type} <span>{props.isOnModeration && '(на модерации)'}</span>
            </UI.BoxHeader>
            <div className={cn('ad-modal__content')}>
                <UI.Content className={cn('ad-modal__content__controls')}>
                    <Link
                        title={'Посмотреть событие с костюмом'}
                        to={`/events/${props.item.event.id}?is_sell_suit=true&person_id=${props.item.person.id}`}
                        target={'_blank'}
                        className={cn('ad-modal__content__controls__photo', props.isOnModeration && 'ad-modal__content__controls__photo-moderation')}
                    >
                        <img src={props.item.cover} />
                    </Link>
                </UI.Content>
                {!isEditMode && (
                    <UI.Main className={cn('ad-modal__content__info')}>
                        <Link
                            className={cn('ad-modal__content__info__item')}
                            title={'Посмотреть профиль персоны'}
                            to={`/persons/${props.item.person.id}`}
                            target={'_blank'}
                        >
                            <div className={cn('ad-modal__content__info__item-title')}>
                                <FontAwesomeIcon
                                    icon={faUser}
                                    className={cn('ad-modal__content__info__item-title-icon')}
                                    title={'Имя персоны'}
                                />
                            </div>
                            <div className={cn('ad-modal__content__info__item-main')}>
                                {props.item.person.name}
                            </div>
                        </Link>
                        <div className={cn('ad-modal__content__info__item-suit')}>
                            <div className={cn('ad-modal__content__info__item-suit-item')}>
                                <div className={cn('ad-modal__content__info__item-title')}>
                                    <FontAwesomeIcon
                                        icon={faTshirt}
                                        className={cn('ad-modal__content__info__item-title-icon')}
                                        title={'Параметры костюма'}
                                    />
                                </div>
                                <div className={cn('ad-modal__content__info__item-main', 'ad-modal__content__info__item-main-props')}>
                                    {personGender ? `${personGender}, ` : ''}{props.item.suit_size}, {props.item.min_height}-{props.item.max_height}
                                </div>
                            </div>
                            <div className={cn('ad-modal__content__info__item-suit-item')}>
                                <div className={cn('ad-modal__content__info__item-title')}>
                                    <FontAwesomeIcon
                                        icon={faEyeDropper}
                                        className={cn('ad-modal__content__info__item-title-icon')}
                                        title={'Цвет костюма'}
                                    />
                                </div>
                                <div className={cn('ad-modal__content__info__item-color', `ad-modal__content__info__item-color-${getColor(props.item.color.id)}`)} />
                            </div>

                        </div>
                        {props.item.comment && (
                            <div className={cn('ad-modal__content__info__column-item')}>
                                <div className={cn('ad-modal__content__info__column-item-title')}>
                                    Комментарий
                                </div>
                                <div className={cn('ad-modal__content__info__item-main-comment')}>
                                    {props.item.comment}
                                </div>
                            </div>
                        )}
                        {props.isOwner && (
                            <div className={cn('ad-modal__content__info__column-item')}>
                                <div className={cn('ad-modal__content__info__column-item-title')}>
                                    Контактная информация
                                </div>
                            </div>
                        )}
                        {props.isOwner && (
                            <div className={cn('ad-modal__content__info__item')}>
                                <div className={cn('ad-modal__content__info__item-title')}>
                                    <FontAwesomeIcon
                                        icon={faUser}
                                        className={cn('ad-modal__content__info__item-title-icon')}
                                        title={'Имя продавца'}
                                    />
                                </div>
                                <div className={cn('ad-modal__content__info__item-main')}>
                                    {props.item.user.name}
                                </div>
                            </div>
                        )}
                        {props.isOwner && (
                            <div className={cn('ad-modal__content__info__item')}>
                                <div className={cn('ad-modal__content__info__item-title')}>
                                    <FontAwesomeIcon
                                        icon={faPhone}
                                        className={cn('ad-modal__content__info__item-title-icon')}
                                        title={'Номер телефона'}
                                    />
                                </div>
                                <div className={cn('ad-modal__content__info__item-main')}>
                                    {props.item.phone_number}
                                </div>
                            </div>
                        )}
                        {props.item.additional_phone_number && props.isOwner && (
                            <div className={cn('ad-modal__content__info__item')}>
                                <div className={cn('ad-modal__content__info__item-title')}>
                                    <FontAwesomeIcon
                                        icon={faPhone}
                                        className={cn('ad-modal__content__info__item-title-icon')}
                                        title={'Дополнительный номер телефона'}
                                    />
                                </div>
                                <div className={cn('ad-modal__content__info__item-main')}>
                                    {props.item.additional_phone_number}
                                </div>
                            </div>
                        )}
                        {props.isOwner && (
                            <div className={cn('ad-modal__content__info__item')}>
                                <div className={cn('ad-modal__content__info__item-title')}>
                                    <FontAwesomeIcon
                                        icon={faComment}
                                        className={cn('ad-modal__content__info__item-title-icon')}
                                        title={'Эл. почта'}
                                    />
                                </div>
                                <div className={cn('ad-modal__content__info__item-main')}>
                                    {props.item.email}
                                </div>
                            </div>
                        )}
                        {!props.isOwner && (
                            <div className={cn('ad-modal__content__info__item')}>
                                <div className={cn('ad-modal__content__info__item-title')}>
                                    <FontAwesomeIcon
                                        icon={faComment}
                                        className={cn('ad-modal__content__info__item-title-icon')}
                                        title={'Получить на эл.почту контакты автора объявления'}
                                    />
                                </div>
                                <Button onClick={onClickRespond} title="Получить на эл.почту контакты автора объявления">
                                    Отправить контакты на почту
                                </Button>
                            </div>
                        )}

                    </UI.Main>
                )}
                {isEditMode && (
                    <EditAdModalCard
                        item={props.item}
                        form={form}
                        field={field}
                        onClickCancel={onClickCancel}
                        onClickSubmit={onClickSubmit}
                    />
                )}
                <div />
                {props.isOwner && (
                    <div className={cn('ad-modal__content__controls__buttons')}>
                        {isEditMode ? (
                            <>
                                <Button onClick={onClickCancel}>
                                    Отменить
                                </Button>
                                <Button onClick={onClickSubmit}>
                                    Сохранить
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button onClick={onClickDelete}>
                                    Удалить
                                </Button>
                                <Button onClick={onClickEdit}>
                                    Редактировать
                                </Button>
                            </>
                        )}
                    </div>
                )}
            </div>
        </Modal>
    );
};

export default DetailedModalCard;
