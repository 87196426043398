import { AxiosResponse } from 'axios';
import httpClient from 'src/utils/http-сlient';
import {
    PersonItem,
    PersonSubscription,
    PersonAPIType,
    UserProfileRetrieve,
    Person,
    PersonsSearchFilter,
    UpdateRequestPersonData,
    UpdatePersonPhotoResponse,
    UpdatePersonPhotoData,
} from './types';
import { Page, PaginationResponse } from '../base';

const getPersonsList = (page: Page, filter?: PersonsSearchFilter): Promise<AxiosResponse<PaginationResponse<PersonItem>>> => {
    return httpClient.get<PaginationResponse<PersonItem>>('/api/v2/persons/', {
        params: {
            page: page.pageNumber,
            ...({ page_size: page.pageSize ? page.pageSize : 40 }),
            ...(filter?.file_id && { file_id: filter.file_id }),
            ...(filter?.search && { search: filter.search }),
            ...(filter?.event_id && { event_id: filter.event_id }),
            ...(filter?.photo_id && { photo_id: filter.photo_id }),
            ...(filter?.location_id && { location_id: filter.location_id }),
            ...(filter?.user_id && { user_id: filter.user_id }),
            ...(filter?.is_person_find_partner && { is_person_find_partner: filter.is_person_find_partner }),
            ...(filter?.number_id && { number_id: filter.number_id })
        }
    });
};

const getPersonItem = (id: number): Promise<AxiosResponse<Person>> => {
    return httpClient.get<Person>(`/api/v2/persons/${id}/`);
};

const getAvatarItem = (id: number): Promise<AxiosResponse> => {
    return httpClient.get(`/api/v2/persons/${id}/avatar/`, {
        responseType: 'blob'
    });
};

const createMergePerson = (id: number, person: number): Promise<AxiosResponse<void>> => {
    return httpClient.post<void>(`/api/v2/persons/${id}/merge-requests/`, {
        person
    });
};

const updatePersonPhoto = (id: number, data: UpdatePersonPhotoData): Promise<AxiosResponse<UpdatePersonPhotoResponse>> => {
    return httpClient.put<UpdatePersonPhotoResponse>(`/api/v2/persons/${id}/photo/`, {
        ...data
    });
};

const getPersonProfileRetrieve = (id: number): Promise<AxiosResponse<UserProfileRetrieve>> => {
    return httpClient.get<UserProfileRetrieve>(`/api/v2/persons/${id}/profile`);
};

const getPersonSubscription = (id: number): Promise<AxiosResponse<PersonSubscription>> => {
    return httpClient.get<PersonSubscription>(`/api/v2/persons/${id}/subscriptions/`);
};

const updateRequestPerson = (id: number, payload: UpdateRequestPersonData): Promise<AxiosResponse<void>> => {
    return httpClient.post<void>(`/api/v2/persons/${id}/update-requests/`, payload);
};

const getPersonProfile = (id: number): Promise<AxiosResponse<UserProfileRetrieve>> => {
    return httpClient.get<UserProfileRetrieve>(`/api/v2/persons/${id}/user-profile/`);
};

export default {
    getPersonsList,
    getPersonItem,
    getAvatarItem,
    createMergePerson,
    updatePersonPhoto,
    getPersonProfileRetrieve,
    getPersonSubscription,
    getPersonProfile,
    updateRequestPerson
} as PersonAPIType;
