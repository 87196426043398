import React, { useState, FC, ReactNode, useEffect, useMemo, useCallback } from 'react';
import ReactSelectAsync from 'react-select/async';
import { useTranslation } from 'react-i18next';
import { ValueType } from 'react-select/src/types';
import axios from 'axios';
import debounce from 'lodash.debounce';

import { getEvents } from 'component/api/main';

import useClassnames from 'hook/use-classnames';
import { useCancelToken } from 'component/core/cancel-token';
import Error from 'component/error';
import { TError } from 'component/form/input/types';

import { IProps, IValue } from './types';
import styles from './style';
import style from './index.pcss';
import api from 'src/api';
import { SimpleEvent, SimpleEventsSearchFilter } from 'src/api/events/types';
import { Page } from 'src/api/base';

const EVENTS_LIMIT = 100;
const InputSelect: FC<IProps> = (props) => {
    const cn = useMemo(() => useClassnames(style, props.className, true), []);
    const token = useCancelToken();
    const { t } = useTranslation();

    const [isFocus, setIsFocus] = useState(false);
    const [isValid, setIsValid] = useState<boolean>(false);
    const [isWatch, setIsWatch] = useState<boolean>(!!props.defaultValue);
    const [value, setValue] = useState<ValueType<IValue>>(props.defaultValue || []);
    const [errorExternal, setErrorExternal] = useState<TError>(props.error || null);
    const [errorInternal, setErrorInternal] = useState<TError>(null);
    const [suggests, setSuggests] = useState<Array<IValue>>([]);
    const [suggestsOriginal, setSuggestsOriginal] = useState<Array<IValue>>([]);
    const excludeEmpty = props.excludeEmpty !== undefined ? props.excludeEmpty : true;

    // new event list
    const [eventsTotal, setEventsTotal] = useState<number>(0);
    const [eventsPage, setEventsPage] = useState<number>(1);
    const [isEventsNext, setIsEventsNext] = useState<boolean>(true);
    const [isEventsLoading, setIsEventsLoading] = useState<boolean>(false);
    const [isEventsLoadMore, setIsEventsLoadMore] = useState<boolean>(false);
    const [selectInputValue, setSelectInputValue] = useState<string>('');

    useEffect(() => {
        if (isEventsLoading || isEventsLoadMore) {
            const page: Page = {
                pageNumber: eventsPage,
                pageSize: EVENTS_LIMIT
            };

            const filter: SimpleEventsSearchFilter = {
                ...(props.is_parent_only && { is_parent_only: props.is_parent_only }),
                ...(props.year && { year: props.year }),
                ...(props.location && { location_id: props.location }),
                ...(props.photographer_id && { photographer_id: props.photographer_id }),
                ...(props.person_id && { person_id: props.person_id }),
                ...(selectInputValue.length && { search: selectInputValue }),
                ...(props.is_video && { is_video: props.is_video })
            };

            api.events.getSimpleEventsList(page, filter)
                .then((resp) => {
                    const options = resp.data.results.map((event): IValue => ({
                        label  : event.name,
                        value  : String(event.id),
                        payload: event
                    }));

                    setEventsTotal(resp.data.count);
                    setIsEventsNext(!!resp.data.next);
                    setEventsPage((prev) => prev + 1);
                    setIsEventsLoadMore(false);
                    setIsEventsLoading(false);
                    setSuggestsOriginal((prev) => isEventsLoadMore ? [...prev, ...options] : options);
                    setSuggests((prev) => isEventsLoadMore ? [...prev, ...options] : options);
                })
                .catch((e) => {
                    setIsEventsNext(false);
                    setIsEventsLoadMore(false);
                    setIsEventsLoading(false);
                });
        }
    }, [isEventsLoading, isEventsLoadMore]);

    const onLoadOptions = debounce((q: string, callback) => {
        setEventsPage(1);
        setIsEventsNext(true);
        const page: Page = {
            pageNumber: 1,
            pageSize: EVENTS_LIMIT
        };

        const filter: SimpleEventsSearchFilter = {
            ...(props.year && { year: props.year }),
            ...(props.location && { location_id: props.location }),
            ...(props.photographer_id && { photographer_id: props.photographer_id }),
            ...(props.person_id && { person_id: props.person_id }),
            ...(q && { search: q })
        };

        api.events.getSimpleEventsList(page, filter)
            .then((resp) => {
                const options = resp.data.results.map((event): IValue => ({
                    label  : event.name,
                    value  : String(event.id),
                    payload: event
                }));

                setEventsTotal(resp.data.count);
                setIsEventsNext(!!resp.data.next);
                setEventsPage((prev) => prev + 1);
                setIsEventsLoadMore(false);
                setIsEventsLoading(false);
                setSuggestsOriginal((prev) => isEventsLoadMore ? [...prev, ...options] : options);
                setSuggests((prev) => isEventsLoadMore ? [...prev, ...options] : options);
                callback(options);
            })
            .catch((e) => {
                setIsEventsNext(false);
                setIsEventsLoadMore(false);
                setIsEventsLoading(false);
                callback(null);
            });
    }, 150);

    useEffect(() => {
        if(props.default_id) {
            const newValue = suggests.find((suggest) => suggest.value === String(props.default_id));

            if(newValue) {
                setValue(newValue);
            }
        }
    }, [JSON.stringify(suggests), props.default_id]);

    const checkValidity = (): boolean => {
        let newIsValid = true;
        let newErrorInternal: TError = null;

        if(props.required) {
            newIsValid = !!value;

            if(!newIsValid) {
                newErrorInternal = t('components.form.input-select.error');
            }
        }

        if(newIsValid && errorExternal) {
            newIsValid = false;
        }

        setIsValid(newIsValid);
        setErrorInternal(newErrorInternal);

        return newIsValid;
    };

    useEffect((): void => {
        checkValidity();
    }, [isWatch]);

    useEffect(() => {
        props.registry.set(props.name, {
            setError: setErrorExternal,
            value,
            clear   : () => {
                setValue(null);
                setIsWatch(false);
            },
            isAutoFill: false,
            isValid: checkValidity()
        });
    }, [value]);

    useEffect(() => {
        setIsEventsLoading(true);

        return () => {
            props.registry.remove(props.name);
        };
    }, []);

    useEffect(() => {
        if(props.defaultValue) {
            setValue(props.defaultValue);
        }
    }, [JSON.stringify(props.defaultValue)]);

    useEffect(() => {
        setIsEventsNext(true);
        setEventsPage(1);
        setIsEventsLoading(true);

        return () => {
            props.registry.remove(props.name);
        };

    }, [props.year, props.location]);

    useEffect(() => {
        const handler = props.registry.onChange();

        if(handler) {
            handler();
        }
    }, [value, isValid]);

    const onChange = (inputValue: ValueType<IValue> | null): void => {
        setValue(inputValue);

        if(errorExternal) {
            setErrorExternal(null);
        }

        if(props.onChange) {
            props.onChange(inputValue);
        }
    };

    const onInputChange = (inputValue: string): void => {
        if (!inputValue.length) {
            setSelectInputValue(inputValue);
            setEventsPage(1);
            setIsEventsNext(true);
            setIsEventsLoading(true);
        }
    };

    const onFocus = (): void => {
        if(!isFocus) {
            setIsFocus(true);
        }
    };

    const onBlur = (): void => {
        if(isFocus) {
            setIsFocus(false);
        }

        if(!isWatch) {
            setIsWatch(true);
        }
    };

    const loadMoreEvents = () => {
        if (isEventsNext && !isEventsLoading && !isEventsLoadMore) {
            setIsEventsLoadMore(true);
        }
    };

    const elLabel = useMemo((): ReactNode => {
        if(props.children) {
            return (
                <strong
                    className={cn('input__label', {
                        'input__label_required': props.required
                    })}
                >
                    {props.children}
                </strong>
            );
        }
    }, [props.children, props.required]);

    const elError = (): ReactNode => {
        if((isWatch && errorInternal) || errorExternal) {
            return <Error elIcon={true} className={cn('input__error')}>{errorInternal || errorExternal}</Error>;
        }
    };

    const elInput = (): ReactNode => {
        const selectStyles = props.styles ? { ...styles(!!props.icon), ...props.styles } : styles(!!props.icon);

        return (
            <ReactSelectAsync
                className={cn('input__field', {
                    'input__field_invalid': isWatch && !isValid
                })}
                autoFocus={props.autoFocus}
                onMenuScrollToBottom={loadMoreEvents}
                placeholder={props.placeholder || t('components.form.input-select.placeholder')}
                isMulti={props.isMulti}
                isSearchable={props.searchable}
                isClearable={props.clearable}
                tabIndex={props.tabIndex}
                isDisabled={props.disabled}
                loadOptions={onLoadOptions}
                defaultOptions={suggestsOriginal}
                value={value}
                defaultValue={value}
                onFocus={onFocus}
                onBlur={onBlur}
                options={suggests}
                onInputChange={onInputChange}
                onChange={onChange}
                styles={selectStyles}
            />
        );
    };

    return (
        <label className={cn('input')}>
            <div className={cn('input__wrapper', `input__wrapper_${props.direction}`)}>
                {elLabel}
                {elInput()}
            </div>
            {elError()}
        </label>
    );
};

InputSelect.defaultProps = {
    direction: 'row'
};

export default InputSelect;
