import { AxiosResponse } from 'axios';
import { Page, PaginationResponse } from '../base';
import {
    AdItem,
    AdListData,
    AdsAPIType,
    CreateAdData,
    PartialUpdateAdData,
    UpdateAd,
    UpdateAdData
} from './types';
import httpClient from 'src/utils/http-сlient';
import queryString from 'query-string';

const getAdList = (
    page: Page,
    filter?: AdListData
): Promise<AxiosResponse<PaginationResponse<AdItem>>> => {
    return httpClient.get<PaginationResponse<AdItem>>('/api/v2/ads/', {
        params: {
            page: page.pageNumber,
            ...{ page_size: page.pageSize ? page.pageSize : 40 },
            ...filter
        },
        paramsSerializer(params) {
            return queryString.stringify(params, {arrayFormat: 'none'});
        }
    });
};

const createAd = (data: CreateAdData): Promise<AxiosResponse<AdItem>> => {
    return httpClient.post<AdItem>('/api/v2/ads/', data);
};

const getAdItem = (id: number): Promise<AxiosResponse<AdItem>> => {
    return httpClient.get<AdItem>(`/api/v2/ads/${id}/`);
};

const updateAd = (
    id: number,
    data: UpdateAdData
): Promise<AxiosResponse<UpdateAd>> => {
    return httpClient.put<UpdateAd>(`/api/v2/ads/${id}/`, data);
};

const partialUpdateAd = (
    id: number,
    data: PartialUpdateAdData
): Promise<AxiosResponse<UpdateAd>> => {
    return httpClient.patch<UpdateAd>(`/api/v2/ads/${id}/`, data);
};

const destroyAd = (id: number): Promise<AxiosResponse<void>> => {
    return httpClient.delete<void>(`/api/v2/ads/${id}/`);
};

const closeCreate = (id: number): Promise<AxiosResponse<void>> => {
    return httpClient.post<void>(`/api/v2/ads/${id}/close/`);
};

const createRespond = (id: number): Promise<AxiosResponse<void>> => {
    return httpClient.post<void>(`/api/v2/ads/${id}/respond/`);
};

export default {
    getAdList,
    createAd,
    getAdItem,
    updateAd,
    partialUpdateAd,
    destroyAd,
    closeCreate,
    createRespond
} as AdsAPIType;
